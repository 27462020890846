<template>
  <div :style="style">
    <span>{{ userInitials }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef, type CSSProperties, type PropType } from "vue";
import { useContactColors } from "@/hooks/contacts";

export interface AvatarTheme {
  text: string;
  background: string;
}

const props = defineProps({
  username: {
    type: String,
    required: true
  },
  theme: {
    type: Object as PropType<AvatarTheme>
  },
  size: {
    type: Number,
    default: 50
  },
  rounded: {
    type: Boolean,
    default: true
  }
});

const style = computed(() => {
  const value: CSSProperties = {
    display: "flex",
    width: `${props.size}px`,
    height: `${props.size}px`,
    minWidth: `${props.size}px`,
    borderRadius: props.rounded ? "50%" : "0",
    lineHeight: `${props.size + Math.floor(props.size / 20)}px`,
    fontWeight: "medium",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    userSelect: "none",
    fontSize:
      Math.round(props.size / 2.5) < 9
        ? "9px"
        : `${Math.round(props.size / 2.5)}px`,
    color: props.theme ? props.theme.text : themeColor.value.text,
    backgroundColor: props.theme
      ? props.theme.background
      : themeColor.value.background
  };

  return value;
});

const { userInitials, themeColor } = useContactColors(toRef(props, "username"));
</script>
